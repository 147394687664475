.blog-entry-content {
    font-family: Graphik Web, sans-serif;
    font-size: 1.389vw;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0;
    color: #231f20;
    -webkit-font-smoothing: antialiased;
}

.blog-entry-content a,
.blog-entry-content a:visited,
.blog-entry-content a:hover,
.blog-entry-content a:active {
    color: #0075ff;
    overflow-wrap: break-word;
}

.blog-entry-content p,
.blog-entry-content h1,
.blog-entry-content h2,
.blog-entry-content h3,
.blog-entry-content h4,
.blog-entry-content h5,
.blog-entry-content h6 {
    margin: 0 0 1.389vw;
}

.blog-entry-content p:first-of-type {
    margin-top: 0;
}

.blog-entry-content p:last-of-type {
    margin-bottom: 0;
}

.blog-entry-content img {
    height: auto;
    width: auto;
    padding: 2.500vw;
    margin-block: 0;
    margin: 0.833vw auto;
    position: inherit;
    object-fit: contain;
    max-width: 100%;
}

.blog-entry-content .size-full img {
    width: 100%;
}

.blog-entry-content h1 {
    font-family: 'GT Alpina', serif;
    font-size: 2.778vw;
    font-weight: 400;
    line-height: 115%;
    color: #231f20;
    letter-spacing: -0.03em;
}

.blog-entry-content h2 {
    font-family: 'GT Alpina', serif;
    font-size: 2.778vw;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -0.03em;
    color: #231f20;
    margin-top: 5.556vw;
    margin-bottom: 2.500vw;
}

.blog-entry-content h3 {
    font-family: 'GT Alpina', serif;
    font-size: 1.944vw;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -0.03em;
    color: #231f20;
    margin-top: 3.333vw;
    margin-bottom: 0.833vw;
}

.blog-entry-content h4 {
    font-weight: bold;
    margin-top: 1.667vw;
}

.blog-entry-content .wp-block-verse {
    margin: 0 0 40px;
    white-space: normal;
    overflow: auto;
    font-family: 'Graphik Web', sans-serif;
    font-weight: bold;
    padding-right: 80px;
    color: #231f20;
    position: relative;
    font-size: 1.389vw;
    line-height: 140%;
    padding-left: 160px;
}

.blog-entry-content .wp-block-verse p {
    margin: 0;
}

.blog-entry-content .wp-block-verse::before {
    content: '';
    background-color: #03c8ff;
    position: absolute;
    top: 50%;
    left: 43px;
    width: 60px;
    height: 5px;
    transform: translateY(-50%);
}

.blog-entry-content .wp-block-quote {
    display: block;
    white-space: normal;
    overflow: visible;
    font-family: 'GT Alpina', sans-serif;
    font-size: 2.778vw;
    line-height: 115%;
    letter-spacing: -0.03em;
    color: #231f20;
    position: relative;
    margin-top: 3vh;
    margin-bottom: 6vh;
    width: 65vw;
    right: 50%;
    left: 50%;
    margin-left: -32vw;
}

.blog-entry-content .wp-block-quote p {
    width: 60vw;
    display: inline;
    margin: 0;
}

.blog-entry-content .wp-block-quote::before {
    position: inherit;
    top: 116px;
    font-size: 236px;
}

.blog-entry-content .wp-block-pullquote {
    margin: 40px 0 40px;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.22);
    background-color: #fff;
    padding: 10px;
}

.blog-entry-content .wp-block-pullquote blockquote {
    margin: 0;
    border: 2px solid #000;
    padding: 20px;
}

.blog-entry-content .wp-block-pullquote blockquote p {
    margin: 0;
    font-family: 'Graphik Web', sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 115%;
    text-align: center;
    color: #231f20;
}

.blog-entry-content ul,
.blog-entry-content ol {
    margin-bottom: 1.667vw;
    margin-top: 1.667vw;
    display: block;
    -webkit-margin-before: 1.667vw;
    margin-block-start: 1.667vw;
    -webkit-margin-after: 1.667vw;
    margin-block-end: 1.667vw;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
    -webkit-padding-start: 0.972vw;
    padding-inline-start: 0.972vw;
}

.blog-entry-content ul {
    list-style-type: disc;
}

.blog-entry-content ol {
    list-style-type: none;
    counter-reset: li;
}

.blog-entry-content li {
    margin-bottom: 1.111vw;
    margin-left: 0.972vw;
    font-size: 1.389vw;
    line-height: 140%;
    text-align: left;
}

.blog-entry-content ul li {
    position: relative;
}

.blog-entry-content ol li {
    counter-increment: li;
    position: relative;
}

.blog-entry-content ol li::before {
    content: counter(li) '.';
    position: absolute;
    top: -1px;
    left: -28px;
    text-align: center;
    color: #231f20;
}

.blog-entry-content figcaption {
    margin-top: -40px;
    margin-left: -20px;
    font-size: 16px;
}

.blog-entry-content table {
    margin-bottom: 20px;
}

.blog-entry-content th,
.blog-entry-content td {
    padding-top: 25px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    border-left: 1px solid #F1F2F2;
    border-right: 1px solid #F1F2F2;
    border-top: 1px solid #231F20;
    border-bottom: 1px solid #231F20;
}

.blog-entry-content th {
    background-color: #CAFDFF;
    border-left: 1px solid #a0cccb;
    border-right: 1px solid #a0cccb;
    border-top: 0;
    padding-bottom: 35px;
}

.blog-entry-content th:first-child {
    border-top: 0;
    border-top-left-radius: 12px;
    border-left: 0;
}

.blog-entry-content th:last-child {
    border-top-right-radius: 12px;
    border-right: 0;
}

.blog-entry-content th p {margin-bottom: 0;}

.blog-entry-content td:first-child {
    border-left: 0;
}

.blog-entry-content td:last-child {
    border-right: 0;
}

/*wide screen*/

@media only screen and (min-width: 1920px) {

    .blog-entry-content p,
    .blog-entry-content h1,
    .blog-entry-content h2,
    .blog-entry-content h3,
    .blog-entry-content h4,
    .blog-entry-content h5,
    h6 {
        margin: 0 0 20px;
    }

    .blog-entry-content h1 {
        font-size: 40px;
        line-height: 115%;
    }

    .blog-entry-content h2 {
        font-size: 40px;
        line-height: 115%;
        margin-top: 80px;
        margin-bottom: 36px;
    }

    .blog-entry-content h3 {
        font-size: 28px;
        line-height: 115%;
        margin-top: 48px;
        margin-bottom: 12px;
    }

    .blog-entry-content h4 {
        font-size: 20px;
        line-height: 140%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .blog-entry-content p,
    .blog-entry-content li {
        font-size: 20px;
        line-height: 140%;
    }

    .blog-entry-content ol,
    .blog-entry-content ul {
        -webkit-padding-start: 14px;
        padding-inline-start: 14px;
    }

    .blog-entry-content li {
        margin-left: 14px;
      	margin-top: 16px;
      	margin-bottom: 16px;
    }

    .blog-entry-content img {
        padding: 36px;
        margin: 12px auto;
    }
}

/*tablet*/

@media only screen and (max-width: 1023px) {

    .blog-entry-content p,
    .blog-entry-content h1,
    .blog-entry-content h2,
    .blog-entry-content h3,
    .blog-entry-content h4,
    .blog-entry-content h5,
    .blog-entry-content h6 {
        margin: 0 0 2.083vw;
    }

    .blog-entry-content h1 {
        font-size: 5.208vw;
        line-height: 115%;
        margin-top: 4.167vw;
        margin-bottom: 2.604vw;
    }

    .blog-entry-content h2 {
        font-size: 5.208vw;
        line-height: 115%;
        margin-top: 4.167vw;
        margin-bottom: 2.604vw;
    }

    .blog-entry-content h3 {
        font-size: 3.646vw;
        line-height: 115%;
        margin-top: 2.604vw;
        margin-bottom: 1.563vw;
    }

    .blog-entry-content h4 {
        font-size: 2.604vw;
        line-height: 140%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .blog-entry-content p,
    .blog-entry-content li,
    .blog-entry-content span {
        font-size: 2.604vw;
        line-height: 140%;
    }

    .blog-entry-content ol,
    .blog-entry-content ul {
        -webkit-padding-start: 4.167vw;
        padding-inline-start: 4.167vw;
        -webkit-margin-before: 2.604vw;
        margin-block-start: 2.604vw;
        -webkit-margin-after: 2.604vw;
        margin-block-end: 2.604vw;
    }


    .blog-entry-content li {
        margin-left: 1.563vw;
      	margin-bottom: 1.563vw;
      	margin-top: 1.563vw;
    }

    .blog-entry-content img {
        padding: 4.167vw;
        margin: 5.208vw auto;
    }

    .blog-entry-content .wp-block-verse {
        font-size: 2.604vw;
        line-height: 140%;
        padding-right: 0;
        padding-left: 70px;
    }

    .blog-entry-content .wp-block-verse::before {
        left: 0;
        width: 40px;
    }

    .wp-block-quote {
        font-size: 3.646vw;
        margin-block: 6vw;
        width: 65vw;
        margin-left: -32.5vw;
        margin-right: -32.5vw;
    }

    .blog-entry-content .wp-block-quote p {
        font-weight: normal;
        width: 100%;
        display: inline;
    }

    .blog-entry-content .wp-block-quote::before {
        top: 65px;
        font-size: 130px;
    }
}

/*mobile*/

@media only screen and (max-width: 639px) {

    .blog-entry-content p,
    .blog-entry-content h1,
    .blog-entry-content h2,
    .blog-entry-content h3,
    .blog-entry-content h4,
    .blog-entry-content h5,
    .blog-entry-content h6 {
        margin: 0 0 6.400vw;
    }

    .blog-entry-content h1 {
        font-size: 10.667vw;
        line-height: 115%;
        margin-top: 6.400vw;
        margin-bottom: 2.133vw;
    }

    .blog-entry-content h2 {
        font-size: 10.667vw;
        line-height: 115%;
        margin-top: 6.400vw;
        margin-bottom: 2.133vw;
    }

    .blog-entry-content h3 {
        font-size: 7.467vw;
        line-height: 115%;
        margin-top: 6.400vw;
        margin-bottom: 2.133vw;
    }

    .blog-entry-content h4 {
        font-size: 4.267vw;
        line-height: 140%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .blog-entry-content span,
    .blog-entry-content p,
    .blog-entry-content li {
        font-size: 4.267vw;
        line-height: 140%;
    }

    .blog-entry-content ol,
    .blog-entry-content ul {
        -webkit-padding-start: 5.333vw;
        padding-inline-start: 5.333vw;
        margin-block-start: 6.400vw;
        margin-block-end: 6.400vw;
    }

    .blog-entry-content li {
        margin-left: 15px;
        margin-bottom: 3.200vw;
        margin-top: 3.200vw;
    }

    .blog-entry-content img {
        padding: 4.267vw;
        margin: 6.400vw auto;
    }

    .blog-entry-content .wp-block-verse {
        margin-bottom: 40px;
        line-height: 1.32;
    }

    .blog-entry-content .wp-block-quote {
        font-size: 10.667vw;
        width: 100%;
        margin-inline: auto;
        left: unset;
        right: unset;
    }

    .blog-entry-content .wp-block-quote p {
        font-weight: normal;
        width: 100%;
        margin-left: 0;
    }

    .blog-entry-content .wp-block-pullquote blockquote p {
        font-size: 20px;
    }
}

.bg-color-blue img {
    background-color: #00d7ff;
}

.bg-color-blue .wp-block-quote::before {
    color: #00d7ff;
}

.bg-color-ocean img {
    background-color: #0075ff;
}

.bg-color-ocean .wp-block-quote::before {
    color: #0075ff;
}

.bg-color-sky img {
    background-color: #94faff;
}

.bg-color-sky .wp-block-quote::before {
    color: #94faff;
}

.bg-color-sky_50 img {
    background-color: #cafdff;
}

.bg-color-sky_50 .wp-block-quote::before {
    color: #cafdff;
}

.bg-color-sky_25 img {
    background-color: #e5feff;
}

.bg-color-sky_25 .wp-block-quote::before {
    color: #e5feff;
}

.bg-color-pink img {
    background-color: #ff32a0;
}

.bg-color-pink .wp-block-quote::before {
    color: #ff32a0;
}

.bg-color-rose img {
    background-color: #ff0047;
}

.bg-color-rose .wp-block-quote::before {
    color: #ff0047;
}

.bg-color-green img {
    background-color: #00e650;
}

.bg-color-green .wp-block-quote::before {
    color: #00e650;
}

.bg-color-springGreen img {
    background-color: #c2ffab;
}

.bg-color-springGreen .wp-block-quote::before {
    color: #c2ffab;
}

.bg-color-orange img {
    background-color: #ff4100;
}

.bg-color-orange .wp-block-quote::before {
    color: #ff4100;
}

.bg-color-yellow img {
    background-color: #dcf000;
}

.bg-color-yellow .wp-block-quote::before {
    color: #dcf000;
}

.bg-color-lemonYellow img {
    background-color: #ffff5e;
}

.bg-color-lemonYellow .wp-block-quote::before {
    color: #ffff5e;
}

.bg-color-lightLemonYellow img {
    background-color: #ffffd7;
}

.bg-color-lightLemonYellow .wp-block-quote::before {
    color: #ffffd7;
}

.bg-color-violet img {
    background-color: #ac00e7;
}

.bg-color-violet .wp-block-quote::before {
    color: #ac00e7;
}

.bg-color-gold img {
    background-color: #ed9100;
}

.bg-color-gold .wp-block-quote::before {
    color: #ed9100;
}

.bg-color-jade img {
    background-color: #009e73;
}

.bg-color-jade .wp-block-quote::before {
    color: #009e73;
}

.bg-color-peach img {
    background-color: #ffd2b9;
}

.bg-color-peach .wp-block-quote::before {
    color: #ffd2b9;
}

.bg-color-lavender img {
    background-color: #dccbff;
}

.bg-color-lavender .wp-block-quote::before {
    color: #dccbff;
}
