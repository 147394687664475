.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;

  &:hover {
    width: 256px !important;
  }
}

.hamburger {
  span:not(&.open) {
    &:nth-child(1) {
      @apply top-0;
    }
    &:nth-child(2),
    &:nth-child(3) {
      @apply top-2;
    }
    &:nth-child(4) {
      @apply top-4;
    }
  }
  &.open {
    span {
      &:nth-child(1) {
        @apply top-24 w-0 left-1/2;
      }
      &:nth-child(2) {
        @apply transform rotate-45;
      }
      &:nth-child(3) {
        @apply transform -rotate-45;
      }
      &:nth-child(4) {
        @apply top-16 w-0 left-1/2;
      }
    }
  }
}

.footerColumnTitleOpened {
  &::after {
    content: '>';
    @apply absolute inline-block lg:hidden right-2 top-4 transform -rotate-90 scale-y-200 font-thin leading-5;
  }
}

.footerColumnTitleClosed {
  &::after {
    content: '>';
    @apply absolute inline-block lg:hidden right-2 top-4 transform rotate-90 scale-y-200 font-thin leading-5;
  }
}

.mobileInnerNav {
  @apply absolute h-80 overflow-y-scroll flex flex-col top-16 right-2 w-40 bg-white py-2 px-2 border border-coreLightGray rounded-md leading-none z-50;
}
.mobileInnerNavRow:not(:last-child) {
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    right: 152px;
    left: initial;
    top: 5px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #231f20;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 100;
    left: initial;
    right: 151px;
    top: 6px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #fff;
  }
}

.leadNurturingWillChange {
  will-change: transform;
}

.mainNavigationMobile {
  @apply w-full flex flex-col py-16 px-4 fixed bg-white top-16 h-screen z-40;
}

.modalbottomText {
  a {
    @apply font-bold text-black;
  }
}

.carousel-module-container {
  .p-carousel .p-carousel-indicators {
    @apply py-4;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    @apply mr-2 mb-2;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    @apply bg-white bg-white border-solid border border-coreLightGray rounded-full w-3 h-3 md:w-3 md:h-3 md:ml-1 lg:w-4 lg:h-4;
  }
  .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    @apply bg-secondaryLightBlue;
  }
  ul.p-carousel-indicators.p-reset {
    @apply flex mr-10 justify-center px-0 pb-4 md:justify-items-end  md:justify-end md:mr-0;
  }
  .p-carousel .p-carousel-content {
    .p-carousel-next {
      @apply border-none z-10 absolute right-2 mx-0  w-7 md:w-10  xl:w-14;
    }
    .p-carousel-prev {
      @apply border-none absolute left-2 z-10 mx-0 w-7 md:w-10  xl:w-14;
    }
    .p-carousel-next,
    .p-carousel-prev {
      @apply text-transparent;
    }
    .p-carousel-prev:enabled:hover,
    .p-carousel-next:enabled:hover {
      @apply border-none bg-transparent text-white shadow-none;
    }

    .p-carousel-prev:focus,
    .p-carousel-next:focus {
      @apply shadow-none;
    }

    &:hover {
      .p-carousel-next,
      .p-carousel-prev {
        @apply text-white;
      }
    }
  }
  .p-carousel-next-icon.pi.pi-chevron-right:before {
    content: '\2192';
    @apply text-xl md:text-2xl xl:text-5xl font-extralight;
  }
  .p-carousel-prev-icon.pi.pi-chevron-left:before {
    content: '\2190';
    @apply text-xl md:text-2xl xl:text-5xl font-extralight;
  }
}

.reviews-carousel {
  .p-carousel-content,
  .p-carousel-container {
    @apply relative;
  }
  .p-carousel .p-carousel-content {
    .p-carousel-next,
    .p-carousel-prev {
      @apply hidden  md:flex;
    }
  }
  .p-carousel .p-carousel-indicators {
    @apply py-4;
  }
  .p-carousel .p-carousel-indicators.p-carousel-indicator {
    @apply mr-2 mb-2;
  }
  .p-carousel-indicators .p-carousel-indicator button {
    @apply bg-white bg-white border-solid border border-coreLightGray rounded-full m-2.5 w-3 h-3 md:w-2.5 md:h-2.5 md:ml-1;
  }

  .p-carousel-indicators .p-carousel-indicator button {
    @apply opacity-75 bg-gray-500 sm:opacity-100;
  }
  .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    @apply bg-white;
  }

  .p-carousel-content:has(.p-carousel-item-active .blue) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-primaryBlueHover;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .pink) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-nextPink;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .white) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-white;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .ocean) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:bg-primaryBlue sm:opacity-100;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .sky) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:bg-primarySky sm:opacity-100;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .sky_50) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply bg-white sm:bg-secondarySky sm:opacity-100;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .sky_25) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-tertiarySky;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .rose) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-nextRose;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .green) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-nextGreen;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .springGreen) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-springGreen;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .orange) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-orangeRed;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .yellow) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-nextYellow;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .lemonYellow) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:bg-lemonYellow sm:opacity-100;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .lightLemonYellow) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:bg-lightLemonYellow sm:opacity-100;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .violet) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:bg-violet sm:opacity-100;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .gold) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-nextYellow;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .jade) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-lemonYellow;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .black) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-black;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .peach) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-peach;
    }
  }
  .p-carousel-content:has(.p-carousel-item-active .lavender) {
    .p-carousel-indicators .p-carousel-indicator button {
      @apply bg-gray-500 sm:bg-white;
    }
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      @apply opacity-75 bg-white sm:opacity-100 sm:bg-lavender;
    }
  }
  .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    @apply bg-white;
  }
  ul.p-carousel-indicators.p-reset {
    top: 91.6875vw;
    @apply flex absolute mr-0 sm:mr-10 justify-center left-28  md:top-auto md:bottom-0 lg:bottom-8 md:left-1/4 p-4;
  }

  button.p-carousel-prev.p-link,
  button.p-carousel-next.p-link {
    @apply w-auto bg-transparent border-0 hidden h-auto text-black border-none z-10 md:absolute md:bottom-0 md:flex lg:bottom-8;
  }
  button.p-carousel-next.p-link {
    @apply hidden right-4 mx-0 w-7 md:w-14 xl:w-14 md:flex;
  }
  .button.p-carousel-prev.p-link {
    @apply hidden left-4 ml-4 w-7 md:w-12 xl:w-14 md:flex;
  }

  .p-carousel-next-icon.pi.pi-chevron-right:before {
    content: '\2192';
    @apply md:flex md:text-5xl xl:text-5xl font-thin;
  }
  .p-carousel-prev-icon.pi.pi-chevron-left:before {
    @apply md:flex md:text-5xl xl:text-5xl font-thin;
    content: '\2190';
    margin-left: 25px;
  }
}
.p-carousel .p-carousel-content button.p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content button.p-carousel-next:enabled:hover {
  @apply bg-transparent shadow-none border-none shadow-none;
  box-shadow: none;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus,
.p-carousel .p-carousel-content button.p-carousel-prev:focus,
.p-carousel .p-carousel-content button.p-carousel-next:focus {
  box-shadow: none;
}

.inline-mql-text-input {
  height: 52px;
  @media screen and (min-width: 645px) and (max-width: 1024px) {
    height: 40px;
  }
}

.inline-mql-error-field {
  input {
    border: 1.5px solid red;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  //margin: 0;
  -moz-appearance: textfield !important;
}

#yotpo-testimonials-custom-tab {
  .yotpo-form-buttons-wrapper {
    display: none;
  }

  .yotpo-display-wrapper {
    .yotpo-display-wrapper {
      display: none;
    }
  }

  .yotpo-nav-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .nav-tab-sum {
      margin-left: 5px !important;
      position: initial !important;
    }
  }

  .promoted-products-box {
    display: none !important;
  }
}

.next-blue-filter {
  filter: brightness(0.7) saturate(100%) invert(31%) sepia(88%) saturate(3984%) hue-rotate(204deg) brightness(104%) contrast(150%);
}
