@font-face {
  font-family: 'Sharp-Sans';
  src: url('/fonts/sharp-sans/SharpSansDispNo2-Black.woff2') format('woff2'),
    url('/fonts/sharp-sans/SharpSansDispNo2-Black.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/fonts/sharp-sans/SharpSansDispNo2-Black.woff2') format('woff2'),
    url('/fonts/sharp-sans/SharpSansDispNo2-Black.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Regular.woff2') format('woff2'), url('/fonts/poppins/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Light.woff2') format('woff2'), url('/fonts/poppins/Poppins-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Medium.woff2') format('woff2'), url('/fonts/poppins/Poppins-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Bold.woff2') format('woff2'), url('/fonts/poppins/Poppins-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/affdf5/00000000000000003b9b0328/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'aktiv-grotesk';
  src: url('https://use.typekit.net/af/b5ce2a/00000000000000003b9b032d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/graphik/Graphik-Semibold-Web.woff2') format('woff2'), url('/fonts/graphik/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/graphik/Graphik-RegularItalic-Web.woff2') format('woff2'),
    url('/fonts/graphik/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/graphik/Graphik-Regular-Web.woff2') format('woff2'), url('/fonts/graphik/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'GT Alpina';
  src: url('/fonts/alpina/GT-Alpina-Standard-Regular.woff2') format('woff2'),
    url('/fonts/alpina/GT-Alpina-Standard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
